@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.inputButton,
.iconWrapper {
  position: absolute;
  right: 10px;
  top: 11px;
  font-size: $size14;
  font-weight: 500;
  color: #43e0f8;
  cursor: pointer;
}

.iconWrapper {
  top: 11px;
}

.jobID {
  @media only screen and (max-width: $tablet) {
    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }
}
