@import "../../../assets/scss/common";
@import "../../../assets/scss/variables";

.fieldWrapper {
  min-width: 0;

  .error {
    border: 1px solid $colorRed !important;
  }
}

.errorText {
  max-width: 100%;
  color: $colorRed;
}

.customOptionField {
  font-size: 14px;
  color: #2db2c8;
  padding: 10px;

  &:hover {
    background-color: #fafafa;
  }
}
