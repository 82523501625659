@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.alertsWidgetWrapper {
  @extend .widgetWrapper;

  width: calc(50% - 12px);
  max-width: 100%;
  min-height: 193px;
}

.indicators {
  display: inline-flex;
  align-items: center;
  margin: 0 2px;
  color: #475569;
  font-size: $size12;
  margin-bottom: 28px;

  i {
    display: inline-flex;
    padding: 0 5px;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    color: #0f172a;
  }
}

.summaryIndicators {
  @extend .indicators;

  padding: 4px 10px 4px 5px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  margin-bottom: 0;

  i {
    font-size: $size12;
  }
}

.indicatorsWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  padding-top: 20px;
}
