@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.modalSidebar {
  width: 370px;
  padding-right: 20px;

  @media only screen and (max-width: 1100px) {
    width: 100%;
    padding-top: 24px;
    padding-right: 0;
    padding-left: 20px;
  }
}

.modalMain {
  width: calc(100% - 370px);
  padding-left: 20px;

  @media only screen and (max-width: 1100px) {
    width: 100%;
    padding-left: 0;
  }
}

.modalContentWrapper {
  display: flex;

  @media only screen and (max-width: 1100px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

.modalMainContent {
  display: flex;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  padding: 20px 12px 20px;
}

.modalMainContentColumn {
  width: 50%;
  padding: 0 8px;
}

.sidebarSubtitle {
  font-size: $size14;
  margin-bottom: 16px;
  font-weight: 500;
}

.modalFieldWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .emailInput input {
    padding-right: 12px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.modalFieldLabel {
  font-size: $size14;
  color: #64748b;
  width: 120px;
  padding: 10px 0;
  flex-shrink: 0;

  &.wider {
    width: 140px;
  }
}

.inputLabelWrapper {
  display: flex;
  align-items: flex-start;
}

.modalFieldValue {
  font-size: $size14;
  color: #94a3b8;
  padding: 10px 0 10px 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
