@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.fileWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 76px;
  border-radius: 10px;
  border: 1px dashed #e2e8f0;
  width: 100%;
  cursor: pointer;
  background: #f8fafc;
}

.dragging {
  background-color: #b8f6b8;
  cursor: grabbing;
}

.inputFile {
  display: none;
}

.labelText {
  font-family: $secondaryFont;
  font-size: $size14;
  width: 100%;
  text-align: center;
  color: #64748b;

  span {
    color: #43e0f8;
  }
}

.icon {
  margin-bottom: 16px;
}

.uploadedImage {
  max-width: 80%;
  max-height: 100px;
}

.fileNameWrapper {
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  padding: 8px 12px;
  font-size: $size12;
  font-weight: 500;
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.fileName {
  padding-left: 4px;
  max-width: calc(100% - 30px);
}

.clearFile {
  position: absolute;
  right: 8px;
  cursor: pointer;
}
