@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.tableSort {
  font-family: $secondaryFont;
  font-size: $size14;
  color: $colorGrey;
  transition: 0.2s;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  svg {
    stroke: $colorGrey;
    transition: 0.2s;
    margin-left: 2px;
    flex-shrink: 0;
  }

  .desc {
    transform: rotate(180deg);
  }

  &.active,
  &:hover {
    color: $colorBlack;

    svg {
      stroke: $colorBlack;
    }
  }
}
