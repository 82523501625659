@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.orderDetails {
  display: flex;
  justify-content: space-between;
  font-family: $secondaryFont;
}

.orderDetailsItem {
  width: 33%;
}

.orderDetailsLabel {
  display: block;
  font-size: $size14;
  margin-bottom: 4px;
  color: #64748b;
}

.orderDetailsValue {
  font-weight: 500;
  display: block;
}

.separator {
  height: 1px;
  width: 100%;
  margin: 20px 0;
  background: #e2e8f0;
}

.deviceWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $secondaryFont;

  &::before {
    display: block;
    content: url("../../assets/svg/device.svg");
  }

  svg {
    stroke: $colorGrey;
  }

  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;
  }
}

.deviceQtyDelete {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 25px;
}

.deviceQtyWrapper {
  padding-left: 16px;
  margin-right: 20px;
}

.deviceName {
  display: block;
  font-weight: 500;
  margin-bottom: 4px;
}

.deviceQty {
  display: block;
  font-size: $size14;
  color: #64748b;
}

.orderInputWrapper {
  display: inline-flex;
  align-items: center;
}

.orderInput {
  margin: 0 4px;
  height: 40px;
  width: 64px;
  border-radius: 10px;
  border: 1px solid #cbd5e1;
  text-align: center;
  font-size: $size14;
  font-family: $secondaryFont;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }
}

.orderInputButton {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  cursor: pointer;
}

.orderNote {
  font-size: $size12;
  color: #64748b;
  margin-top: 20px;

  &::before {
    content: "*";
    color: $colorBlue;
  }
}
