@import "../../../assets/scss/variables";

.contentWrapper {
  position: relative;
  border-radius: 24px 24px 0 0;
  background: $colorWhite;
  padding: 24px;
  font-family: $secondaryFont;
}

.contentTitle {
  font-size: $size24;
  font-weight: 500;
  margin-bottom: 12px;
  max-width: 700px;
  text-overflow: ellipsis;
  overflow: hidden;
}
