.row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 0 20px;
  margin-bottom: 20px;

  @media only screen and (max-width: 450px) {
    flex-direction: column;
  }
}

.personalInfoRow {
  margin-bottom: 10px !important;
}

.inputText {
  margin-bottom: 0;
}

.phoneNumberContainer {
  flex: 1;
}
