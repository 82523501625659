@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.snTableHeader {
  display: flex;
}

.snHederCell {
  font-size: $size14;
  color: #64748b;
  width: 33.33%;
}

.snBodyRow {
  padding: 16px 0;
  display: flex;
  border-bottom: 1px solid #e2e8f0;

  &:last-child {
    border: none;
    padding: 16px 0 0;
  }
}

.snBodyCell {
  width: 33.33%;
}
