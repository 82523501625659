@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.widgetWrapper {
  width: 100%;
  max-width: calc(50% - 12px);
  flex: 1 0 700px;

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin-bottom: 24px;
  }
}

.chartWrapper {
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 1100px) {
    max-width: 560px;
    margin: 0 auto;
    position: relative;
  }
}

.chart {
  position: absolute;
  top: -36px;
  left: 0;

  @media only screen and (max-width: 1100px) {
    top: -54px;
  }
}

.widgetMainValue {
  position: absolute;
  left: 14px;
  bottom: 30px;
  width: 210px;
}

.chartNotesWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  row-gap: 36px;

  @media only screen and (max-width: 1420px) {
    .rowLastNote {
      width: 14%;
    }
  }

  @media only screen and (min-width: 1201px) and (max-width: 1299px) {
    .rowPreLastNote {
      width: 17%;
    }
  }

  @media only screen and (max-width: 690px) {
    width: 46%;
    gap: 30px 10px;
  }
}

.chartNote {
  font-size: $size12;
  font-weight: 400;
  color: #64748b;
  width: 20%;
  break-inside: avoid-column;

  &:nth-child(3) {
    margin-bottom: 0;
  }

  &::before {
    display: inline-flex;
    position: relative;
    top: 2px;
    width: 4px;
    height: 12px;
    border-radius: 4px;
    margin-right: 6px;
    content: "";
  }

  &.installed {
    &::before {
      background: #14b8a6;
    }
  }

  &.packed {
    &::before {
      background: #a855f7;
    }
  }

  &.inInventory {
    &::before {
      background: #c11574;
    }
  }

  &.inStock {
    &::before {
      background: #22c55e;
    }
  }

  &.missing {
    &::before {
      background: #fcd34d;
    }
  }

  &.preinstalled {
    &::before {
      background: #115e59;
    }
  }

  &.damaged {
    &::before {
      background: #ef4444;
    }
  }

  &.transfering {
    &::before {
      background: #3b82f6;
    }
  }

  &.production {
    &::before {
      background: #cbd5e1;
    }
  }

  &.warranty {
    &::before {
      background: #43e0f8;
    }
  }

  @media only screen and (max-width: 690px) {
    width: 45% !important;
  }
}

.hiddenChartNote {
  @media only screen and (max-width: 690px) {
    display: none;
  }
}

.chartNoteValue {
  display: block;
  font-weight: 500;
  margin-top: 4px;
  font-size: 1rem;
  color: $colorBlack;
  margin-top: 10px;
}
