@import "../../../assets/scss/variables";

.userMenuWrapper {
  position: relative;
}

.userMenuButton {
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background: rgb(255 255 255 / 15%);
  }

  &.active {
    background: rgb(255 255 255 / 15%);
  }
}

.userDropdown {
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 47px;
  border-radius: 8px;
  border: 1px solid #f1f5f9;
  background: $colorWhite;
  box-shadow: 0 16px 32px -12px rgb(71 85 105 / 8%);
  width: 220px;
  overflow: hidden;
}

.userDropdownItem {
  font-family: $secondaryFont;
  padding: 12px;
  border-bottom: 1px solid #e2e8f0;
  transition: 0.2s;
  color: #334155;
  font-size: $size14;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: rgb(71 85 105 / 8%);
  }

  &:last-child {
    border: none;
  }
}

.itemIcon {
  margin-right: 8px;
}

.userAvatarWhite {
  fill: #f8fafc;
}

.userAvatarBlack {
  fill: #475569;
}

.userEmail {
  color: #64748b;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
  white-space: nowrap;
}
