@import "../../../assets/scss/variables";

.barWidgetWrapper {
  display: flex;
  height: 50px;
}

.barWidgetItem {
  border-radius: 5px;
  width: 0;
  transition: width 0.7s ease;
  margin-right: 4px;
  background: #ccc;

  &:last-child {
    margin-right: 0;
  }
}

.noDataMessage {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
