@import "../../../assets/scss/variables";

.buttonNew {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-weight: 500;
  font-size: $size14;
  height: 36px;
  font-family: $secondaryFont;
  transition: all 0.2s ease-out;
  cursor: pointer;

  &:disabled,
  &[disabled] {
    cursor: default;
    opacity: 0.7;
  }

  svg {
    margin-right: 8px;
  }

  // COLORS
  &.blue {
    color: #134f66;
    background: $colorBlue;
  }

  &.tropicalCyan {
    border: 1px solid $colorBlue;
    background: $colorWhite;
    color: #2db2c8;
  }

  &.blueBordered {
    border: 1px solid $colorBlue;
    background: $colorWhite;
    color: $colorBlue;
  }

  &.greyBordered {
    border: 1px solid #cbd5e1;
    background: $colorWhite;
    color: $colorBlack;
  }

  &.grey {
    background: #e2e8f0;
    color: $colorBlack;
  }

  &.orange {
    background: #f59e0b;
    color: $colorWhite;
  }

  &.red {
    color: $colorWhite;
    background: $colorRed;

    svg {
      stroke: $colorWhite;
    }
  }

  &.redBordered {
    border: 1px solid $colorRed;
    color: $colorRed;
    background: $colorWhite;

    svg {
      stroke: $colorRed;
    }
  }

  // SIZES
  &.fullWidth {
    width: 100%;
  }

  &.halfWidth {
    width: calc(50% - 8px);
  }

  &.auto {
    width: auto;
    padding: 0 16px;
  }
}
