@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.widgetStatus {
  @media only screen and (max-width: $tablet) {
    max-width: 100%;
  }
}

.chartWrapper {
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: $tablet) {
    max-width: 450px;
    margin: 0 auto;
    position: relative;
  }
}

.chart {
  position: absolute;
  top: -48px;
  left: -10px;

  @media only screen and (max-width: $tablet) {
    top: -60px;
  }
}

.widgetMainValue {
  position: absolute;
  bottom: 45px;
  left: 94px;
}

.chartNotesWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 90px;

  .chartNotesWorkStatusSection {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    width: 100%;
    height: 45px;

    .chartNote {
      width: 120px;
    }

    @media only screen and (max-width: 1300px) {
      height: 0px;
    }
  }

  .chartNotesStatusSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 18px;

    .chartNote {
      width: 30%;
    }
  }

  @media only screen and (max-width: 1300px) {
    row-gap: 8px;

    .chartNotesStatusSection {
      flex-direction: column;
      row-gap: 8px;

      .chartNote {
        width: 100%;
      }
    }
  }
}

.chartNote {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  font-size: 12px;
  color: #64748b;

  .chartNoteValue {
    margin-left: auto;
    padding-left: 4px;
    font-weight: 500;
    font-size: $size14;
    line-height: 20px;
    color: #0f172a;
  }

  &::before {
    display: inline-flex;
    position: relative;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 4px;
    content: "";
  }

  &.online {
    &::before {
      background: #86efac;
    }
  }

  &.offline {
    &::before {
      background: #e0e7ff;
    }
  }

  &.inventory {
    &::before {
      background: #f5d0ec;
    }
  }

  &.missing {
    &::before {
      background: #fcd34d;
    }
  }

  &.transferring {
    &::before {
      background: #2563eb;
    }
  }

  &.installed {
    &::before {
      background: #86efac;
    }
  }

  &.preinstalled {
    &::before {
      background: #14b8a6;
    }
  }

  &.warranty {
    &::before {
      background: #59afc6;
    }
  }
}
