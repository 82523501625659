@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.modalLink {
  text-decoration: underline;
  color: $colorBlack;
  font-weight: 500;
}

.modalLinksSection {
  margin-top: 1em;
}
