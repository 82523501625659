@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/common";

.alertStatus {
  font-size: $size14;

  &::before {
    display: none;
  }

  &.pending {
    color: #f59e0b;
  }

  &.failed {
    color: #ef4444;
  }

  &.delivered {
    color: #2db2c8;
  }
}
