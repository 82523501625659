@import "../../../assets/scss/variables";

.errorBoundaryWrapper {
  width: 100%;
  height: 100vh;
  background: linear-gradient(114deg, #4ae9ff 3.88%, #627afa 94.88%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorBoundary {
  background: $colorWhite;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: 100%;
  padding: 60px 30px 30px;
}

.errorBoundaryMessage {
  font-size: 1.5rem;
  font-family: $secondaryFont;
  text-align: center;
  margin-bottom: 50px;

  span {
    display: block;
    font-size: 70px;
  }
}

.buttonsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
