@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.tooltipText {
  font-size: $size12;
  color: #64748b;
  font-family: $secondaryFont;

  &::after {
    position: relative;
    top: 2px;
    margin-left: 2px;
    content: url("../../../assets/svg/tooltip-icon.svg");
  }
}

.tooltip {
  background: #fff !important;
  color: #475569 !important;
  font-family: $secondaryFont !important;
  max-width: 280px !important;
  padding: 4px 10px !important;
  border-radius: 6px !important;
  border: 1px solid #e2e8f0 !important;
  box-shadow:
    0 1px 2px 0 rgb(226 232 240 / 24%),
    0 12px 24px 0 rgb(148 163 184 / 12%) !important;
  z-index: 1500 !important;
  font-size: $size12 !important;
  text-align: left !important;
}

.tooltipArrow {
  border-right: 1px solid #e2e8f0 !important;
  border-bottom: 1px solid #e2e8f0 !important;
  z-index: -1 !important;
  box-shadow:
    0 1px 2px 0 rgb(226 232 240 / 24%),
    0 12px 24px 0 rgb(148 163 184 / 12%) !important;
}
