@import "./variables";

// ------------ FORMS ------------- //
.authButtonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.errorMessage {
  font-family: $secondaryFont;
  font-size: $size14;
  color: $colorRed;
  max-width: 75%;
  margin-top: 5px;
  padding-left: 20px;
  position: relative;

  &::before {
    content: url("../svg/icon-error-message.svg");
    position: absolute;
    left: 0;
    top: 0;
  }
}

.fieldWrapper {
  width: 100%;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.fieldLabel {
  font-size: $size12;
  margin-bottom: 8px;
}

.fieldLabelBottom {
  font-size: $size12;
  margin-top: 8px;
}

.field {
  width: 100%;
  position: relative;
}

.fieldLabelNew {
  font-family: $secondaryFont;
  font-size: $size14;
  margin-bottom: 5px;
}

.inputNew {
  height: 40px;
  padding: 0 12px;
  width: 100%;
  font-size: $size14;
  font-family: $secondaryFont;
  padding-right: 50px;
  border-radius: 10px;
  border: 1px solid #e2e8f0;

  &::placeholder {
    font-size: $size14;
    font-family: $secondaryFont;
    color: #94a3b8;
  }

  &[type="password"] {
    font-size: 18px;
  }

  &:focus,
  &:active {
    border: 1px solid #4ae9ff;
    box-shadow:
      0 0 0 4px rgb(205 252 255 / 70%),
      0 0 0 2px #fff;
  }

  &:disabled {
    border: none;
    box-shadow: none;
    background: #f1f5f9;

    &::placeholder {
      color: #cbd5e1;
    }
  }

  &.error {
    border: 1px solid $colorRed;

    &:focus,
    &:active {
      border-color: $colorRed;
      box-shadow: none;
    }
  }
}

.formCellsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.formCell {
  width: calc(50% - 8px);
}

// ------------ TABBLE ------------- //
.tableNavWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.tableNavButtonsWrapper {
  display: flex;
  align-items: center;
}

.tableNavItem {
  margin: 0 6px;
}

.editColumnsButton {
  font-size: 14px;
  font-weight: 500;
  color: #334155;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;

  &::after {
    margin-left: 8px;
    position: relative;
    top: 2px;
    content: url("../svg/icon-pencil.svg");
  }
}

// ------------ MODALS ------------- //
.modalContent {
  padding: 20px;
  max-height: calc(90vh - 145px);
  overflow-y: auto;
  overflow-x: hidden;

  &.center {
    text-align: center;
    font-family: $secondaryFont;
  }

  &.settings {
    max-height: calc(90vh - 205px);
  }

  &.settingsIntegration {
    max-height: calc(90vh - 170px);
  }

  &.performanceTestModalContent {
    max-height: calc(90vh - 170px);
  }

  &.monitoringReportContent {
    max-height: calc(100vh - 100px);
  }
}

.modalTitle {
  font-size: $size18;
  font-weight: 500;
  margin: 16px 0 4px;
}

.modalMessage {
  font-size: $size14;
  line-height: 20px;
  color: #475569;
}

.modalLine {
  width: 100%;
  height: 1px;
  background: #e2e8f0;
  margin: 20px 0;
  position: relative;

  &::after,
  &::before {
    display: block;
    position: absolute;
    height: 1px;
    width: 20px;
    top: 0;
    background: #e2e8f0;
    content: "";
  }

  &::before {
    left: -20px;
  }

  &::after {
    right: -20px;
  }
}

.infoMessage {
  font-family: $secondaryFont;
  font-size: $size14;
  color: #64748b;
  margin-top: 5px;
  padding-left: 20px;
  position: relative;

  &::before {
    content: url("../../assets/svg/icon-info-message.svg");
    position: absolute;
    left: 0;
    top: 0;
  }
}

.modalFooter {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e2e8f0;

  &.buttonsAutoWidth {
    justify-content: flex-end;

    button,
    span {
      margin-left: 12px;
    }
  }
}

.fullWidthTitle {
  width: calc(100% + 40px);
  margin-left: -20px;
  padding: 8px 20px;
  margin-bottom: 20px;
  line-height: 1;
  font-size: $size12;
  text-transform: uppercase;
  color: #64748b;
  background: #f8fafc;
}

// ------------ WIDGETS ------------- //

.widgetsWrapper {
  display: flex;
  justify-content: center;
  padding: 0 12px;
  margin-bottom: 24px;
}

.widgetWrapper {
  border-radius: 20px;
  border: 1px solid #e2e8f0;
  background: $colorWhite;
  padding: 0 32px 20px;
  max-width: 450px;
  margin: 0 12px;
  width: 100%;
  min-height: 316px;
  position: relative;
}

.widgetHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid #f1f5f9;
}

.widgetTitle {
  font-family: $secondaryFont;
  font-size: 1rem;
  font-weight: 500;
}

.widgetDataWrapper {
  padding-top: 16px;
  position: relative;
}

.widgetSpinnerWrapper {
  height: 180px;
  display: flex;
  align-items: center;
}

.widgetFooter {
  border-top: 1px solid #f1f5f9;
  padding-top: 16px;
  display: flex;
  justify-content: center;
}

.widgetFooterItem {
  text-align: center;
  border-right: 1px solid #f1f5f9;
  flex: 1;

  &:last-child {
    border: none;
  }
}

.widgetMainValue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0 10px;
}

.widgetValueWrapper {
  margin-top: 4px;
  font-size: $size24;
  font-weight: 500;
  line-height: 1.5;
}

.widgetNote {
  font-size: $size12;
  color: #64748b;
}

.widgetLoader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.disabled {
  opacity: 0.5;
  cursor: default;
}

.widgetAddress {
  font-size: $size12;
  color: #64748b;
}

.widgetTag {
  font-size: $size12;
  color: #475569;
  border: 1px solid #e2e8f0;
  padding: 4px 10px;
  border-radius: 6px;
}

.widgetTagValue {
  font-weight: 500;
  color: #0f172a;
}

.chartValuesWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.chartValuesTitle {
  font-size: 14px;
  color: #475569;
  display: inline-flex;

  &.green,
  &.dark,
  &.blue {
    &::before {
      display: inline-flex;
      position: relative;
      top: 1px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      border: 2px solid #fff;
      margin-right: 4px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
      content: "";
    }
  }

  &.green {
    &::before {
      background: #22c55e;
    }
  }

  &.dark {
    &::before {
      background: #475569;
    }
  }

  &.blue {
    &::before {
      background: #43e0f8;
    }
  }
}

// ------------ TOP-BAR ------------- //
.topbar {
  width: 100%;
  padding: 0 24px;
  margin-bottom: 24px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2000;

  &.sticky {
    position: sticky;
    top: 0;
    left: 0;
    background: linear-gradient(114deg, #4ae9ff 3.88%, #627afa 94.88%);
  }
}

.logoWrapper {
  display: inline-flex;
}

.logo {
  max-width: 110px;
}

.menuWrapper {
  display: flex;

  @media only screen and (max-width: 1300px) {
    width: 265px;
    height: 100vh;
    background: #fff;
    background: linear-gradient(114deg, #4ae9ff 3.88%, #627afa 94.88%);
    box-shadow: -12px 1px 89px -41px rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 64px;
    padding: 64px 16px 0;
    display: none;
    flex-direction: column;

    &.opened {
      display: flex;
    }
  }
}

.menuItem {
  padding: 8px 20px;
  border-radius: 30px;
  margin: 0 8px;
  font-family: $secondaryFont;
  font-size: $size14;
  font-weight: 500;
  color: $colorWhite;
  transition: 0.2s;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1300px) {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    width: auto;
    margin-bottom: 10px;
  }

  .menuIcon {
    fill: $colorWhite;
    transition: 0.2s;
    margin-right: 8px;
  }

  .menuIconPerformance {
    fill: none;
  }

  .menuIconStroke {
    transition: 0.2s;
    margin-right: 8px;
    stroke: $colorWhite;
  }

  &.active,
  &:hover {
    background: $colorWhite;
    color: #52c2fd;

    .menuIcon:not(.menuIconStroke, .menuIconPerformance) {
      fill: #52c2fd;
    }

    .menuIconPerformance {
      path {
        stroke: #59a6fd;
      }
    }

    .menuIconStroke:not(.menuIcon) {
      stroke: #52c2fd;
    }
  }
}

.redDot {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: rgb(215, 61, 61);
  position: relative;
  right: -6px;
  top: -6px;
  font-size: 8px;
  color: #fff;
}

.screenWrapper {
  display: flex;
  justify-content: center;
}

.link {
  color: #2db2c8;
  text-decoration: underline;
  cursor: pointer;
}
