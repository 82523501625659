@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.paginationWrapper {
  background: $colorWhite;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
}

.paginationText {
  color: #475569;
  font-size: $size14;
}

.pageNumberList {
  display: inline-flex;
  align-items: center;
}

.pageNumber {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #cbd5e1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $size14;
  font-weight: 500;
  margin: 0 4px;
  cursor: pointer;

  svg {
    fill: #475569;
  }

  &.active {
    border-color: #2db2c8;
    background: #ebfcff;
  }

  &.disabled {
    border: none;
    background: #f1f5f9;

    svg {
      fill: #cbd5e1;
    }
  }
}
