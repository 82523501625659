.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;

  h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: #0f172a;
    margin-bottom: 4px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #475569;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 10px;
  background-color: #fef3c7;
  margin-bottom: 16px;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: #f59e0b;
    }
  }
}
