.emptyReportDataContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(100, 100, 100, 0.06);
  border-radius: 8px;
  backdrop-filter: blur(2px);

  div {
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    font-size: 14px;
    background-color: #fff;
  }
}
