@import "../../assets/scss/variables";

.authWrapper {
  height: 100vh;
  background: url("../../assets/img/backgound-logo.png"), linear-gradient(114deg, #4ae9ff 3.88%, #627afa 94.88%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: $desktop) {
    justify-content: center;
  }

  @media only screen and (max-width: $tablet) {
    flex-direction: column-reverse;
    align-items: stretch;
    height: auto;
    padding: 24px 0 0;
  }
}

.logoWrapper {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: $tablet) {
    width: 100%;
  }
}

.logo {
  max-width: 100%;

  @media only screen and (max-width: $laptop) {
    max-width: 70%;
  }

  @media only screen and (max-width: $tablet) {
    max-width: 90px;
    margin-bottom: 24px;
  }
}
