@import "../../../assets/scss/variables";

.filter {
  z-index: 1000;

  &__control--is-focused,
  &__control--menu-is-open {
    border: none !important;
    box-shadow: none !important;
  }

  &__control {
    border: none !important;
    background: #f8fafc !important;
  }

  &__menu {
    margin: 0.125rem auto;
  }

  &__option {
    background-color: white;

    &--is-focused {
      background-color: lightblue;
    }
  }

  &__group {
    padding: 0;
  }

  &__menu-portal {
    border: 1px solid darkblue;
  }

  &__dropdown-indicator {
    position: relative;
    cursor: pointer;
  }

  &__indicator-separator {
    display: none;
  }

  &__clear-indicator {
    display: none !important;
  }

  &__multi-value {
    border-radius: 8px !important;
    padding: 0 0 0 6px !important;
    border: 1px solid #f1f5f9;
    background: $colorWhite !important;
    overflow: hidden;
  }

  &__multi-value__label {
    padding: 0 !important;
    font-size: $secondaryFont;
    font-size: 12px;
    font-weight: 500;
  }

  &__multi-value__remove {
    cursor: pointer;
    margin-left: 2px;
    transition: 0.1s;
  }

  &__menu {
    overflow-x: hidden;
    border-radius: 8px !important;
    border: 1px solid #f1f5f9 !important;
    box-shadow: 0 16px 32px -12px rgb(71 85 105 / 10%) !important;
  }

  &__menu-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &__option {
    color: #334155 !important;
    font-weight: 500;
    cursor: pointer !important;
  }
}
