@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.paginationWrapper {
  background: $colorWhite;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;

  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;
  }

  ul {
    list-style: none;
  }
}

.paginationText {
  color: #475569;
  font-size: $size14;
}
