.subscriptionModal {
  footer {
    display: none;
  }
}

.subscriptionModalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 90vh;
  overflow: hidden;
}

.subscriptionModalWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 70px 40px 0 40px;
}

.subscriptionModalSection {
  display: flex;
  width: 100%;

  &:nth-child(n + 2) {
    .subscriptionModalFeature {
      display: none;
    }
  }
}

.subscriptionModalPrice {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;

  .subscriptionModalPriceOld {
    position: relative;
    font-size: 16px;
    color: #64748b;
    text-align: center;
  }
}

.subscriptionModalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;

  div {
    display: flex;
    gap: 24px;

    span {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;

      svg {
        margin-right: 8px;
      }
    }
  }
  .subscriptionModalFooterText {
    span {
      font-size: 12px;
    }
  }
}

.subscriptionModalContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.subscriptionModalFeature {
  .subscriptionModalRow {
    justify-content: start;

    &:last-child {
      border-bottom: 0;
    }
  }

  .featureRow {
    &:last-child {
      font-weight: 600;
    }
  }

  .subscriptionModalRowTitle {
    align-items: start;
    padding: 0 24px 0 0;
  }
}

.popularPlan {
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100% + 20px);
  border-radius: 16px;
  border: 2px solid #2db2c8;
  position: absolute;
  top: -20px;

  .mostPopularText {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -16px;
    height: 32px;
    border-radius: 8px;
    padding: 4px 12px;
    white-space: nowrap;
    background: linear-gradient(to right, #43e0f8 0%, #2db2c8 100%);

    p {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.subscriptionModalRow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-bottom: 1px solid #e2e8f0;

  &:nth-last-child(2) {
    border-color: #64748b;
  }

  &:last-child {
    border-bottom: 0;
  }

  p {
    font-size: 14px;
  }
  .priceText {
    font-size: 18px;
    font-weight: 700;
  }
}

.subscriptionModalRowTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 85px;
  border-bottom: 1px solid #e2e8f0;
  padding: 0 12px;

  .featuresTitle {
    padding-top: 40px;
  }

  p {
    white-space: nowrap;
    font-size: 24px;
    font-weight: 700;
  }
  p.boldTitle {
    font-weight: 900;
    font-style: italic;
    text-transform: uppercase;
  }
  div {
    padding-top: 4px;
    font-weight: 600;
    height: 14px;
    font-size: 14px;
    color: #22c55e;
  }
  span {
    font-family: "Inter";
    font-style: italic;
    font-size: 20px;
    font-weight: 700;
  }
}

.popularPlanTitle {
  padding: 0 30px;
}

@media only screen and (max-width: 850px) {
  .subscriptionModalRowTitle {
    span {
      font-size: 16px;
    }
    p {
      font-size: 18px;
    }
  }
  .subscriptionModalRow {
    p {
      font-size: 12px;
    }
    .priceText {
      font-size: 14px;
    }
    svg {
      width: 16px;
    }
  }
  .subscriptionModalFooter {
    span {
      font-size: 12px;
    }
    svg {
      width: 16px;
      margin-right: 6px;
    }
    div {
      gap: 16px;
    }
    div:first-child {
      span {
        font-size: 10px;
      }
    }
  }
  .popularPlan .mostPopularText {
    height: 28px;

    p {
      font-size: 12px;
    }
  }
}

.subscriptionSubModal {
  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 20px;

    p {
      font-size: 14px;
      color: #475569;
      margin-top: 4px;
      text-align: center;
    }

    span {
      font-weight: 700;
    }
  }

  .subscriptionSubModalFooter {
    flex-direction: column-reverse;
    gap: 12px;
  }

  .iconWrapper {
    display: flex;
    width: 44px;
    height: 44px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    background-color: #fef3c7;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .subscriptionModalWrapper {
    flex-direction: column;
    overflow-y: auto;
  }
  .subscriptionModalSection {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(n + 2) {
      .subscriptionModalFeature {
        display: flex;
      }
    }
  }
  .subscriptionModalContent {
    .subscriptionModalRowTitle {
      padding: 0;
    }
  }
}

@media only screen and (max-width: 590px) {
  .subscriptionModalFooter {
    align-items: start;
    .subscriptionModalFooterText {
      align-items: start;
    }
    .subscriptionModalFooterContacts {
      flex-direction: column;
      align-items: end;
    }
  }
}
