@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.toggleFieldWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.saveButton {
  position: absolute;
  z-index: 2;
  top: 20px;
  right: 52px;
  width: 120px;
  height: 36px;
  border-radius: 20px;
  background-color: #43e0f8;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  color: #165964;
  cursor: pointer;

  @media only screen and (max-width: 450px) {
    width: 60px;
  }
}

.contractorContainer {
  overflow: scroll;
  height: calc(100vh - 76px);
}

.addButton {
  display: inline-flex;
  align-items: center;
  color: #2db2c8;
  font-weight: 500;
  font-size: $size14;
  cursor: pointer;

  &::after {
    position: relative;
    left: 4px;
    top: 2px;
    display: inline-flex;
    content: url("../../../assets/svg/icon-plus-blue.svg");
  }
}

.closeIconClassName {
  z-index: 2;
  top: 18px;
}

.inputText {
  margin-bottom: 0;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 0 20px;
  margin-bottom: 20px;

  > div {
    flex: 1;
  }

  @media only screen and (max-width: 450px) {
    flex-direction: column;

    &.subscriptionRow {
      flex-direction: row;
    }
  }
}

.directionColumn {
  flex-direction: column;
  gap: 5px;
}

.sectionTitleInfoIcon {
  path {
    fill: #64769b;
  }
}

.titleInformationIcon {
  span {
    &::after {
      display: block;
      transform: scale(1.2);
    }
  }
}

.subscriptionSectionModalLine {
  margin: 0px;
}

.subscriptionPlanName {
  div {
    &:nth-child(1) {
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 500;
      color: #0f172a;
    }

    &:nth-child(2) {
      font-size: 14px;
      color: #64769b;
    }
  }
}

.subscriptionDropdownContainer {
  max-width: 140px;

  :global(.react-dropdown-select) {
    border: 0 !important;
    box-shadow: none !important;
    outline: none;
  }

  :global(.react-dropdown-select-content) {
    min-width: 95px;
    color: #2db2c8;
  }

  :global(.react-dropdown-select-dropdown-handle) {
    color: #2db2c8;
    right: 15px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  :global(.react-dropdown-select-dropdown) {
    right: 10px;
    width: 200px;
    left: auto;
  }
}
