@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.tableNavButtonsWrapper {
  display: flex;
}

.confirmedCheckboxWrapper {
  display: flex;
  align-items: center;
  margin: 0 6px;

  label {
    margin-right: 8px;
  }
}

.startPerformanceButton {
  background-color: #43e0f8 !important;
  color: #165964 !important;

  &[disabled] {
    background-color: #f1f5f9 !important;
    color: #cbd5e1 !important;
    border: 1px solid #f1f5f9;
  }
}

.cancelButton {
  padding: 2px 8px !important;
  background-color: #ef4444 !important;
  color: #fff !important;
  border: 1px solid #ef4444 !important;
  font-size: 12px !important;
  height: unset !important;
  margin-left: 10px !important;

  &[disabled] {
    opacity: 0.5 !important;
  }
}
