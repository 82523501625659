@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.spinnerWrapper {
  position: absolute;
  z-index: 1;
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputText {
  margin-bottom: 5px;
}

.emailAlertsCheckbox {
  margin-bottom: 15px;
}
