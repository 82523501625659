@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.contentWrapper {
  background: $colorWhite;
  overflow-x: scroll;
  min-height: calc(100vh - 190px);
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
}

.backButton {
  margin-top: -48px;
  position: relative;
}

.sectionWrapper {
  width: calc(50% - 12px);
  max-height: 550px;

  @media only screen and (max-width: 1200px) {
    width: 100%;

    &:first-child {
      margin-bottom: 24px;
    }
  }
}
