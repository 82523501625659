@import "../../assets/scss/common";

.modalSubtitle {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #f8fafc;

  p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: #64748b;
  }
}

.performanceTestModalContent {
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px 16px;
}

.contentRow {
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid #e2e8f0;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  flex-grow: 1;
  row-gap: 16px;
}

.detailsInfo {
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #64748b;
    text-transform: uppercase;
    margin-bottom: 6px;
    letter-spacing: 1px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #0f172a;
  }

  span {
    font-size: 12px;
    color: #94a3b8;
  }
}

.statusContainer {
  display: flex;
  align-items: center;
  gap: 5px;

  span {
    display: block;
  }
}

div.testStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e8f0;
  padding: 4px 8px;
  border-radius: 6px;
  width: fit-content;

  span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
  }
}

div.passed {
  span {
    background-color: #22c55e;
  }

  p {
    color: #15803d;
  }
}

div.issue {
  span {
    background-color: #ef4444;
  }

  p {
    color: #b91c1c;
  }
}

.reportDiagramContainer {
  position: relative;
  margin-top: 5px;
  height: 200px;

  canvas {
    padding: 5px 3px;
  }
}
