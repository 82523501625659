@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.valueNote {
  font-size: 12px;
  font-weight: 400;
  color: #64748b;
}

.widgetInventory {
  @media only screen and (max-width: 1300px) {
    max-width: 924px;
    margin-bottom: 24px;
  }

  @media only screen and (max-width: $tablet) {
    max-width: 100%;
  }
}
