@import "./functions";

// COLORS
$colorBlack: #000;
$colorWhite: #fff;
$colorRed: #ef4444;
$colorGrey: #64748b;
$colorLightGrey: #f8faff;
$colorPurple: #7864ff;
$colorLightBlue: #e6f9f8;
$colorBlue: #42e8ff;

// FONTS
$mainFont: "Inter";
$secondaryFont: "Inter";
$size12: 0.75rem;
$size13: 0.8125rem;
$size14: 0.875rem;
$size18: 1.125rem;
$size20: 1.25rem;
$size22: 1.375rem;
$size24: 1.5rem;
$size32: 2rem;

// BREAKPOINTS
$desktop: 1540px;
$laptop: 1439px;
$tablet: 1024px;
