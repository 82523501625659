@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.widgetWrapper {
  width: 100%;
  max-width: calc(50% - 12px);

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin-bottom: 0;
  }
}

.chartWrapper {
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 1300px) {
    max-width: 513px;
    margin: 0 auto;
    position: relative;
  }
}

.chart {
  position: absolute;
  top: 16px;
  left: 0;

  @media only screen and (max-width: 1300px) {
    top: 0;
  }
}

.widgetMainValue {
  position: absolute;
  left: 0px;
  bottom: 63px;
  width: 210px;
}

.chartNotesWrapper {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  height: 203px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: calc(100% - 230px);
}

.chartNotesWrapperColumn {
  height: 100%;
  display: flex;
  flex: 1;
  flex-flow: column;
  padding-top: 25px;
}

.chartNote {
  font-size: 12px;
  font-weight: 400;
  color: #64748b;
  display: inline-flex;
  justify-content: start;
  font-size: $size12;
  border-bottom: 1px solid #f1f5f9;
  margin-bottom: 22px;
  padding-bottom: 22px;

  &:last-child {
    border-bottom: none;
  }

  &::before {
    display: inline-flex;
    position: relative;
    top: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 4px;
    content: "";
  }

  &.new::before {
    background-color: #4ade80;
  }

  &.pending::before {
    background-color: #fde047;
  }

  &.confirmed::before {
    background-color: #43e0f8;
  }

  &.packed::before {
    background-color: #7e22ce;
  }

  &.shipping::before {
    background-color: #2563eb;
  }

  &.denied::before {
    background-color: #ef4444;
  }
}

.chartNoteValue {
  font-weight: 500;
  padding-left: 4px;
  font-size: $size14;
  color: $colorBlack;
  margin-left: auto;
}
