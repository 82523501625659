@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.tableFiltersWrapper {
  display: flex;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  margin: 16px 0;
  position: relative;
}

.datePickerWrapper {
  border-radius: 10px;
  position: absolute;
  z-index: 1001;
  right: -206px;
  top: 44px;
  border: 1px solid #f1f5f9;
  background-color: #fff;
  box-shadow: 0px 16px 40px -8px #47556921;

  div:first-child {
    border: none;
  }

  .dateButtonsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-top: 1px solid #f1f5f9;

    button {
      color: #165964;
    }
  }
}

.innerWrapper {
  background-color: yellow;
}

.tableFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-right: 1px solid #e2e8f0;
  cursor: pointer;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #475569;
  }

  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-right: none;
  }

  &:hover {
    background-color: #f8fafc;
  }

  &.active {
    background-color: #aff2fd;
  }
}
