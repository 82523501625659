@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.tableDateWrapper {
  display: inline-flex;
  font-family: $secondaryFont;
  font-size: $size14;
  color: $colorGrey;
  cursor: pointer;

  svg {
    stroke: $colorGrey;
    transition: 0.2s;
    margin-left: 2px;
  }
}

.inputWrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: #f8fafc;
  height: 100%;
}

.valueWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: #f8fafc;
  padding-left: 8px;
  height: 100%;
}

.value {
  color: $colorBlack;
}

.inputSearch {
  height: 100%;
  width: calc(100% - 40px);
  padding: 8px 0 8px 12px;
  font-family: $secondaryFont;
  font-size: $size14;
  background: #f8fafc;

  &::placeholder {
    color: #cbd5e1;
    font-family: $secondaryFont;
    font-size: $size14;
  }
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 40px;

  &:hover {
    svg {
      stroke: $colorBlack;
    }
  }
}
