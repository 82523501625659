@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.editor {
  height: 170px;
  overflow-y: auto;
  width: 100%;
  font-size: $size14;
  font-family: $mainFont;
  padding-left: 15px;
  padding-right: 50px;
  border: 1px solid #dde4ee;
  border-radius: 10px;

  &:focus,
  &:active {
    border: 1px solid #a7b8d0;
  }

  &.error {
    border: 1px solid $colorRed;

    &:focus,
    &:active {
      border-color: $colorRed;
      box-shadow: none;
    }
  }
}
