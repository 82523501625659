@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.valueNote {
  font-size: 12px;
  font-weight: 400;
  color: #64748b;
}

.devicesWrapper {
  width: 100%;
  max-width: 920px;
  min-height: 240px;
}

.valueNote {
  font-size: $size12;
  color: #64748b;
  display: inline-flex;
  align-items: center;

  &:before {
    display: inline-flex;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 4px;
    content: "";
  }

  &.green {
    &::before {
      background-color: #4ade80;
    }
  }

  &.yellow {
    &::before {
      background-color: #fde047;
    }
  }

  &.red {
    &::before {
      background-color: #ef4444;
    }
  }

  &.turquoise {
    &::before {
      background-color: #43e0f8;
    }
  }

  &.purple {
    &::before {
      background-color: #a855f7;
    }
  }
}

.devicesFooter {
  margin-top: 16px;
}
