@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.subMenuWrapper {
  position: relative;

  .menuIconStroke {
    margin: 0 0 0 8px;
  }
}

.subMenuList {
  top: 44px;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  z-index: 1000;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 16px 32px -12px rgba(71, 85, 105, 0.1);
}

.subMenuItem {
  width: 185px;
  font-family: $secondaryFont;
  font-size: $size14;
  font-weight: 500;
  color: #0f172a;
  transition: 0.2s;
  display: flex;
  align-items: center;
  padding-left: 8px;

  .menuIcon {
    fill: $colorBlue;
    transition: 0.2s;
    margin-right: 8px;
  }
}
