@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.widgetWrapper {
  width: 100%;
  max-width: 100%;
  min-height: 170px;

  @media only screen and (max-width: 1200px) {
    margin-bottom: 24px;
  }
}

.chart {
  height: 20px;
}
