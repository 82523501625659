@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.widgetWrapper {
  @media only screen and (max-width: $tablet) {
    max-width: 100%;
    margin-bottom: 24px;
  }
}

.chartWrapper {
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: $tablet) {
    max-width: 450px;
    margin: 0 auto;
    position: relative;
  }
}

.chart {
  position: absolute;
  top: 16px;
  left: 0;

  @media only screen and (max-width: $tablet) {
    top: 0;
  }
}

.widgetMainValue {
  position: absolute;
  left: 0px;
  bottom: 63px;
  width: 210px;
}

.chartNotesWrapper {
  padding-top: 10px;
}

.chartNote {
  font-size: 12px;
  font-weight: 400;
  color: #64748b;
  width: 100%;

  &::before {
    display: inline-flex;
    position: relative;
    top: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 4px;
    content: "";
  }

  &.green {
    &::before {
      background: #22c55e;
    }
  }

  &.yellow {
    &::before {
      background: #fcd34d;
    }
  }

  &.purple {
    &::before {
      background: #2563eb;
    }
  }
}

.chartNoteValue {
  font-weight: 500;
  padding-left: 4px;
  font-size: $size14;
  color: $colorBlack;
}

.chartNoteLine {
  display: block;
  width: 110px;
  height: 1px;
  background: #f1f5f9;
  margin: 25px 0;
}
