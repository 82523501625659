@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.pageDropdownWrapper {
  position: relative;
  font-size: $size14;
  font-weight: 500;
}

.paginationDropdownWrapper {
  position: relative;
  font-size: $size14;
  font-weight: 500;
}

.paginationDropdown {
  padding: 0 40px 0 12px;
  height: 32px;
  width: 120px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #cbd5e1;
  background: $colorWhite;
  cursor: pointer;
  position: relative;
}

.paginationIcon {
  position: absolute;
  right: 12px;
  top: 6px;
  transform: rotate(180deg);

  &.active {
    transform: rotate(0deg);
  }
}

.paginationDropdownList {
  position: absolute;
  z-index: 1000;
  bottom: 100%;
  border-radius: 8px;
  border: 1px solid #f1f5f9;
  background: #fff;
  box-shadow: 0 16px 32px -12px rgb(71 85 105 / 10%);
  width: 120px;
}

.paginationDropdownItem {
  padding: 10px;
  transition: 0.2s;
  position: relative;
  cursor: pointer;

  &:hover {
    background: #f1f5f9;
  }

  &.active {
    &::after {
      display: inline-flex;
      position: absolute;
      right: 8px;
      top: 10px;
      content: url("../../../assets/svg/icon-pagination-check.svg");
    }
  }
}
