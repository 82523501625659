@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/common";

.alertStatus {
  text-transform: uppercase;
  font-size: $size12;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 20px;
  border: 1px solid #ccc;
  color: #ccc;

  &::before {
    display: none;
  }

  &.packed {
    background: #fef3c7;
    color: #78350f;
    border-color: #b45309;
  }

  &.confirmed {
    background: #dcfce7;
    color: #14532d;
    border-color: #15803d;
  }

  &.shipping {
    background: #dbeafe;
    color: #264af8;
    border-color: #2563eb;
  }
}
