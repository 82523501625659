@import "../../../assets/scss/variables";

.spinnerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  svg {
    margin-right: 0 !important;
  }

  @media only screen and (max-width: $tablet) {
    svg {
      width: 28px !important;
      height: 28px !important;
    }
  }
}
