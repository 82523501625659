@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.logoField {
  display: flex;
  justify-content: space-between;
}

.imageWrapper {
  width: 72px;
  height: 72px;
  border-radius: 10px;
  border: 1px solid #e2e8f0;
  background: #f8fafc;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.fileWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 88px);
  height: 72px;
  border-radius: 10px;
  border: 1px dashed #e2e8f0;
  background: #f8fafc;
  cursor: pointer;

  &.error {
    border: 1px solid $colorRed;
  }
}

.dragging {
  background-color: #b8f6b8;
  cursor: grabbing;
}

.inputFile {
  display: none;
}

.labelText {
  font-size: $size12;
  text-align: center;
  color: #64748b;

  span {
    font-weight: 500;
    color: #43e0f8;
  }
}

.uploadedImage {
  max-width: 72px;
  max-height: 72px;
}
