@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.widgetsWrapper {
  @media only screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
}

.actionButtonsWrapper {
  &.disabled {
    .actionButton {
      border: 1px solid #cbd5e1 !important;
      background: #f1f5f9 !important;
      color: #cbd5e1 !important;
      cursor: default !important;
    }
  }
}

.actionButton {
  font-size: $size12;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.2;
  padding: 2px 8px;
  border-radius: 20px;
  margin-right: 12px;
  cursor: pointer;

  &.transfer {
    border: 1px solid #3b82f6;
    background: #dbeafe;
    color: #1e40af;
  }

  &.missing {
    border: 1px solid #f59e0b;
    background: #fef3c7;
    color: #b45309;
  }

  &.damaged {
    border: 1px solid #ef4444;
    background: #fee2e2;
    color: #b91c1c;
  }

  &.warehouse {
    border: 1px solid #64748b;
    background: #f1f5f9;
    color: #1e293b;
  }

  &.warranty {
    border: 1px solid #43e0f8;
    background: #ebfcff;
    color: #165964;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}
