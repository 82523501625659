@import "../../../assets/scss/variables";

.filterTagsWrapper {
  display: flex;
  align-items: center;
  margin: 12px 0;
  min-height: 36px;
}

.filterTitle {
  font-size: $size14;
  font-weight: 500;
  display: inline-flex;
  align-items: center;

  &::after {
    content: "";
    display: inline-flex;
    width: 1px;
    height: 20px;
    background: #e2e8f0;
    margin-left: 12px;
  }
}

.filterTagsList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -6px;
  list-style: none;
}

.filterTag {
  padding: 0 12px 0 16px;
  height: 36px;
  display: flex;
  align-items: center;
  margin-left: 12px;
  background: #ebfcff;
  border-radius: 32px;
  font-size: $size14;
  margin-bottom: 6px;
}

.filterTagName {
  color: #2db2c8;
}

.filterTagValues {
  margin-left: 4px;
  color: #0f172a;
  font-weight: 500;
}

.filterTagClose {
  width: 20px;
  height: 20px;
  display: inline-flex;
  margin-left: 4px;
  cursor: pointer;

  &::before {
    content: url("../../../assets/svg/icon-filter-tag-close.svg");
  }
}
