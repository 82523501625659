@import "../../../assets/scss/variables";

.hamburgerLines {
  display: none;
  z-index: 1000;
  height: 26px;
  width: 32px;
  position: absolute;
  top: 19px;
  right: 76px;
  z-index: 2;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  @media only screen and (max-width: 1300px) {
    display: flex;
  }

  .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #fff;
  }

  .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.2s ease-in-out;
  }

  .line2 {
    transition: transform 0.1s ease-in-out;
  }

  .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.2s ease-in-out;
  }
}

.opened {
  .line1 {
    transform: rotate(45deg);
  }
  .line2 {
    transform: scaleY(0);
  }
  .line3 {
    transform: rotate(-45deg);
  }
}
