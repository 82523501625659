@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.widgetWrapper {
  width: 100%;
  max-width: 100%;
  min-height: 193px;

  @media only screen and (max-width: $tablet) {
    width: 100%;
  }
}

.chartWrapper {
  padding: 16px 0;
}

.indicatorsWrapper {
  margin-top: 16px;
}

.indicator {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #475569;
  text-transform: uppercase;
  margin-right: 20px;

  i {
    font-style: normal;
    color: #0f172a;
    padding-left: 4px;
  }
}

.colorCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-flex;
  margin-right: 6px;
}
