@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.templateList {
  border-radius: 20px;
  border: 1px solid #e2e8f0;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.table {
  min-height: 100%;
  top: 0;
}

.tabsWrapper {
  width: 100%;
  display: inline-flex;
  height: 40px;
  padding: 4px;
  margin-bottom: 20px;
  border-radius: 32px;
  background: #f1f5f9;
}

.tab {
  width: 50%;
  height: 32px;
  border-radius: 32px;
  padding: 0 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  margin-right: 4px;
  background: #f1f5f9;
  color: #94a3b8;
  cursor: pointer;
  &.active {
    background: #fff;
    color: #334155;
    box-shadow:
      0 1px 3px 0 rgb(51 65 85 / 4%),
      0 1px 2px 0 rgb(51 65 85 / 4%);
  }
  &:last-child {
    margin-right: 0;
  }
  &.disabled {
    box-shadow: none;
    background: #f1f5f9;
  }
  &.grey {
    box-shadow: none;
    background: #f1f5f9;
    color: #94a3b8;
    cursor: pointer;
  }
}
