@import "../../../assets/scss/variables";

.authSection {
  background: $colorWhite;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 64px);
  width: 720px;

  @media only screen and (min-width: $desktop) {
    min-height: 780px;
  }

  @media only screen and (max-width: $laptop) {
    max-width: 600px;
  }

  @media only screen and (max-width: $tablet) {
    width: 100%;
    min-height: calc(100vh - 100px);
    max-width: 100%;
    border-radius: 20px 20px 0 0;
    justify-content: start;
  }
}

.formWrapper {
  width: 100%;
  max-width: 475px;

  @media only screen and (max-width: $laptop) {
    max-width: 400px;
  }

  @media only screen and (max-width: $tablet) {
    max-width: 100%;
    padding: 32px 16px 16px;
  }
}

.formTitle {
  font-size: 3rem;
  margin-bottom: 16px;
  font-family: $secondaryFont;

  @media only screen and (max-width: $tablet) {
    font-size: $size24;
    text-align: center;
  }
}

.formSubtitle {
  color: #64748b;
  margin-bottom: 32px;

  @media only screen and (max-width: $tablet) {
    text-align: center;
  }
}
