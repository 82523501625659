@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.dataWrapper {
  display: flex;
  margin-bottom: 16px;
}

.dataItem {
  width: 50%;
}

.dataItemTitle {
  font-size: $size14;
  color: #64748b;
  margin-bottom: 8px;
}

.dataItemText {
  font-weight: 500;
}

.line {
  width: 100%;
  height: 1px;
  background: #e2e8f0;
  margin-bottom: 16px;
}

.inputFileWrapper {
  position: relative;
  margin-bottom: 16px;
}

.spinnerWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.errorMessageFullWith {
  max-width: 100%;
}
