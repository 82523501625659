@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.widgetWrapper {
  margin: 0 12px;

  @media only screen and (max-width: $tablet) {
    max-width: 100%;
  }
}

.valueNote {
  font-size: 12px;
  font-weight: 400;
  color: #64748b;
}

.chartWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: relative;
}

.chart {
  position: absolute;
  bottom: -126px;
}

.text {
  font-size: $size12;
  color: #64748b;
  font-family: $secondaryFont;
}

.available,
.packed {
  display: inline-flex;
  align-items: center;

  &::before {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-flex;
    margin-right: 4px;
    content: "";
  }
}

.available {
  &:before {
    background-color: #fef3c7;
  }
}

.packed {
  &:before {
    background-color: #fcd34d;
  }
}
