@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.iconButton {
  position: absolute;
  right: 12px;
  top: 10px;
  opacity: 0.8;
  transform: scale(0.9);
  cursor: pointer;

  &:hover {
    transition: 0.15s;
    opacity: 0.6;
  }
}

.showIcon {
  display: inline-flex;
  content: url("../../../assets/svg/eyeClosed.svg");
}

.hideIcon {
  display: inline-flex;
  content: url("../../../assets/svg/eyeOpened.svg");
}
