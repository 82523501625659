@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.tableWrapper {
  background: $colorWhite;
  overflow-x: scroll;
  min-height: calc(100vh - 266px);
}

.table {
  font-family: $secondaryFont;
  font-size: $size14;
  line-height: 1.5;
  display: inline-block;
}

.tableHeaderCell,
.tableCell {
  flex: 0 0 300px;
  width: 300px;
  box-sizing: border-box;
  transition: 0.2s;
  word-break: break-word;
  word-wrap: break-word;

  &.withoutSizeSettings {
    flex: 0 0 auto;
    width: 100%;
  }

  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
  }

  .blue {
    color: #2db2c8;
  }

  .orange {
    color: #f59e0b;
  }

  .red {
    color: #ef4444;
  }

  &.action {
    color: #2db2c8;
    font-weight: 500;
    cursor: pointer;
  }
}

.markdownWrapper {
  display: block;

  ul {
    li {
      ul,
      ol {
        padding-left: 20px;
      }
    }
  }
}

.tableCell {
  &:nth-last-of-type(2) {
    border-radius: 0 8px 8px 0;
  }
}

.tableHeaderCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: #f8fafc;
  padding-left: 12px;
  color: $colorGrey;

  &.withoutGaps {
    padding: 0 !important;
  }
}

.tableHeader {
  position: sticky;
  top: 0;
  z-index: 1000;
  margin-bottom: 2px;
  display: flex;
  border-radius: 8px;
  min-height: 38px;
}

.tableRow {
  transition: 0.2s;
  display: flex;

  .rowNavVisibility {
    opacity: 0;
  }

  &.active {
    border-radius: 8px;
    background: #f8fafc;

    .tableCell {
      background: #f8fafc;
    }
  }

  &:hover {
    border-radius: 8px;
    background: #f8fafc;

    .tableCell {
      background: #f8fafc;
    }

    .rowNavVisibility {
      opacity: 1;
    }
  }

  &.redFlag {
    border-radius: 8px;

    .tableCell {
      background: #fee2e2;
    }

    &:hover {
      .tableCell {
        background: rgb(251, 213, 213) !important;
      }
    }
  }
}

.clickableRow {
  cursor: pointer;
}

.tableRowSelected {
  border-radius: 8px;
  background: #f8fafc;

  .tableCell {
    background: #f8fafc;
  }
}

.tableCell {
  padding: 22px 12px;
  display: inline-flex;
  align-items: center;
  border-bottom: 1px solid #f1f5f9;

  &.companyName {
    padding: 0 12px;
  }

  &.groupHeader {
    flex: 1;
    height: 35px;
    background: #f8fafc;
    border-bottom: none;
    border-radius: 8px;
  }
}

.spinnerWrapper {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.booleanValue {
  display: inline-flex;
  align-items: center;

  &::before {
    display: inline-flex;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 6px;
  }

  &.enable {
    &::before {
      background: #22c55e;
    }
  }

  &.disable {
    &::before {
      background: #f59e0b;
    }
  }
}

.dynamicNavButton {
  font-size: $size14;
  color: #334155;
  padding: 0 12px;
  height: 32px;
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: 32px;
  border: 1px solid #e2e8f0;
  background: #fff;
}

.monitoringIconsWrapper,
.messageTemplateButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 4px;
  background-color: #f8fafc;
  gap: 8px;
  cursor: initial;

  span {
    height: 32px;
    width: 32px;
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;

    &.disabledNavButton {
      cursor: not-allowed;

      svg {
        opacity: 0.4;
      }
    }

    svg {
      width: 20px;
      height: 20px;
      fill: transparent;
      path {
        stroke: #64748b;
      }
    }

    &:hover {
      background-color: #e2e8f0;

      svg path {
        stroke: #0f172a;
      }
    }
  }

  &.redFlag {
    background-color: #fbd5d5;

    span {
      &:hover {
        background-color: #fbd5d5;
      }
    }
  }
}

.messageTemplateButtonContainer {
  gap: 0px;

  span {
    height: 28px;
    width: 28px;
    padding: 4px;

    &:hover {
      background-color: transparent;

      svg path {
        stroke: #0f172a;
      }
    }
  }
}

.tooltipArrow {
  display: none;
}

.tooltip {
  font-family: $secondaryFont !important;
  padding: 4px 10px !important;
  border-radius: 6px !important;
  border: 1px solid #e2e8f0 !important;
  box-shadow:
    0 1px 2px 0 rgb(226 232 240 / 24%),
    0 12px 24px 0 rgb(148 163 184 / 12%) !important;
  font-size: $size12 !important;
  text-align: center !important;
  position: absolute !important;
}

.rowNavWrapper {
  display: flex;
  border-radius: 4px;
  align-self: center;
  position: sticky;
  right: 0px;
  z-index: 1000;
  transition: 0.2s;
  width: 0px;
  justify-content: flex-end;

  &.redFlag {
    .rowNavButtonsContainer {
      background-color: #fbd5d5;
    }
  }
}

.rowNavButtonsContainer {
  display: flex;
  background: #f8fafc;
  gap: 0px;
  padding-right: 5px;
}

.rowNavButton {
  position: relative;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  cursor: pointer;

  svg {
    stroke: $colorGrey;
    transition: 0.2s;
  }

  &:hover {
    svg {
      stroke: #334155;
    }
  }
}
