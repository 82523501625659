@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/common";

.forgotPasswordLink {
  font-size: $size14;
  font-weight: 500;
  font-family: $secondaryFont;
  transition: 0.15s;
  margin-top: 16px;
  color: #2db2c8;
}

.textLink {
  border-bottom: 1px solid $colorGrey;
  transition: 0.15s;
  margin-left: auto;
  cursor: pointer;

  &:hover {
    border-color: transparent;
  }
}

.errorMessageWrapper {
  margin-bottom: 16px;
}

.inputFooter {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: -16px;
}
