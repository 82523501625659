@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.widgetWrapper {
  margin: 0 12px;
  width: 100%;
  max-width: calc(50% - 12px);

  @media only screen and (max-width: 1200px) {
    margin-bottom: 24px;
    max-width: 100%;
  }
}

.valueNote {
  font-size: 12px;
  font-weight: 400;
  color: #64748b;
}

.chartWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: relative;
}

.chart {
  position: absolute;
  bottom: -126px;
}

.text {
  font-size: $size12;
  color: #64748b;
  font-family: $secondaryFont;
}

.online,
.offline {
  display: inline-flex;
  align-items: center;

  &::before {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-flex;
    margin-right: 4px;
    content: "";
  }
}

.online {
  &:before {
    background-color: #22c55e;
  }
}

.offline {
  &:before {
    background-color: #e2e8f0;
  }
}
