@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.orderItem {
  margin-bottom: 8px;
  font-family: $secondaryFont;
  list-style: none;

  &:last-child {
    margin-bottom: 0;
  }
}

.orderItemName {
  color: #64748b;
}

.orderItemValue {
  display: inline-flex;
  margin-left: 8px;
  font-weight: 500;
}
