.react-dropdown-select {
  min-height: 40px !important;
  border-color: #dde4ee !important;
  border-radius: 10px !important;
  padding: 0 12px !important;
}

.react-dropdown-select:focus-within {
  box-shadow: none;
}

.react-dropdown-select:hover {
  box-shadow: none !important;
  border-color: #dde4ee !important;
}

.react-dropdown-select:focus-within {
  /* box-shadow: none !important;
  border-color: #a7b8d0 !important; */

  border: 1px solid #4ae9ff !important;
  box-shadow:
    0px 0px 0px 4px rgba(205, 252, 255, 0.7),
    0px 0px 0px 2px #fff !important;
}

.react-dropdown-select-item:hover {
  background: rgba(0, 0, 0, 0.02) !important;
}

.react-dropdown-select-item-selected {
  background: #fff !important;
  color: #000 !important;
  font-weight: 500;
  position: relative;
}

.react-dropdown-select-item-selected:after {
  content: url("../svg/icon-check.svg");
  position: absolute;
  right: 10px;
}

.react-dropdown-select-item {
  padding: 10px 16px !important;
}

.react-dropdown-select-dropdown-handle {
  padding: 5px;
  right: -5px;
  position: relative;
  opacity: 0.6;
}

.react-dropdown-select .react-dropdown-select-dropdown-handle {
  z-index: 10 !important;
}

.react-dropdown-select-content,
.react-dropdown-select-input {
  font-size: 14px !important;
  overflow: hidden;
}

.react-dropdown-select-content span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 10px);
}

.react-dropdown-select-dropdown {
  border-radius: 10px !important;
  border: 1px solid #e2e8f0 !important;
  box-shadow: 0px 16px 32px -12px rgba(71, 85, 105, 0.1) !important;
  max-height: 200px;
  z-index: 2000 !important;
}

.react-dropdown-select-input {
  &::placeholder {
    color: #94a3b8;
  }
}

.react-dropdown-select-item {
  font-size: 14px;
}
