@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.widgetWrapper {
  width: 100%;
  max-width: 100%;
  min-height: 150px;
}

.chart {
  height: 16px;
}

.chartItemWrapper {
  margin-bottom: 20px;
}
