@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.widgetsWrapper {
  @media only screen and (max-width: 1300px) {
    flex-wrap: wrap;
  }
}

.widgetsWrapperJustified {
  padding: 0 6px;
  gap: 32px;

  div {
    max-width: unset;
    margin: 0;
  }
}

.statusButtonWrapper {
  margin-right: 12px;
}

.statusButton {
  display: inline-flex;
  font-size: $size12;
  font-weight: 500;
  text-transform: uppercase;
  padding: 2px 8px;
  border-radius: 20px;
  margin-left: 4px;
  cursor: pointer;

  &.disabled {
    cursor: default;
    pointer-events: none;
  }

  &.transfer {
    color: #164d63;
    border: 1px solid #06b3d4;
    background: #cff9fe;
  }

  &.missing {
    color: #b45309;
    border: 1px solid #f59e0b;
    background: #fef3c7;
  }

  &.warranty {
    border: 1px solid #2db2c8;
    background: #ebfcff;
    color: #165964;
  }

  &.inventory {
    border: 1px solid #fcceee;
    background: #fdf2fa;
    color: #c11574;
  }

  &.preinstalled {
    border: 1px solid #14b8a6;
    background: #dffbf4;
    color: #115e59;
  }
}
