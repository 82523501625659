@import "../../../assets/scss/variables";

.breadcrumbsWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.breadcrumb {
  font-size: $size14;
  font-weight: 500;
  color: #64748b;
  display: flex;
  align-items: center;
  // cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: #0f172a;

    .breadcrumbIcon {
      fill: #0f172a;
    }
  }

  &:last-child {
    color: #0f172a;

    .breadcrumbIcon {
      display: none;
    }
  }
}

.breadcrumbIcon {
  fill: #cbd5e1;
  margin: 0 6px;
  position: relative;
  top: 1px;
  transition: 0.2s;
}
