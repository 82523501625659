@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.viewport {
  background: rgb(46 50 62 / 40%);
  backdrop-filter: blur(8px);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
}

.layout {
  position: fixed;
  box-shadow: 0 16px 40px -8px rgb(71 85 105 / 13%);
  background: $colorWhite;
  z-index: 3100;
}

.modal {
  left: 50%;
  top: 50%;
  width: calc(100% - 8px);
  transform: translate(-50%, -50%);
  max-width: 1190px;
  max-height: 90vh;
  border-radius: 20px;
}

.drawer {
  width: 100%;
  height: 100vh;
  right: 0;
  top: 0;
}

.closeIcon {
  padding: 10px;
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
  z-index: 2;

  &::before {
    content: url("../../assets/svg/icon-close.svg");
  }
}

.additionalButton {
  margin-left: 0 !important;
  margin-right: auto;
}
