@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.inputNumberWrapper {
  display: inline-flex;
  align-items: center;
}

.inputNumber {
  margin: 0 4px;
  height: 40px;
  width: 70px;
  border-radius: 10px;
  border: 1px solid #cbd5e1;
  text-align: center;
  font-size: $size14;
  font-family: $secondaryFont;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }
}

.inputNumberButton {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  cursor: pointer;

  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
}
