@import "../../../assets/scss/variables";

.checkboxNewWrapper {
  display: inline-flex;
  align-items: center;
  position: relative;

  &.reverse {
    flex-direction: row-reverse;
  }
}

.checkboxNew {
  display: none;
}

.checkboxNewText {
  font-size: $size14;
  padding: 0 8px;
  cursor: pointer;
}

.checkboxNewLabel {
  display: inline-flex;
  width: 20px;
  height: 20px;
  overflow: hidden;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.checkboxNew + .checkboxNewLabel {
  svg {
    position: relative;
    height: 20px;
    min-width: 40px;
    left: -20px;
  }
}

.checkboxNew:checked + .checkboxNewLabel {
  svg {
    left: 0px;
  }
}
