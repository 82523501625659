@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.iconArrow {
  width: 16px;
  height: 16px;

  svg {
    position: absolute;
    top: 9px;
    right: 8px;
  }

  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
}

.iconClear {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  cursor: pointer;

  svg {
    stroke: $colorGrey;
    transition: 0.2s;
    margin-left: 2px;
  }

  &:hover {
    svg {
      stroke: $colorBlack;
    }
  }
}

.clearIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  cursor: pointer;

  svg {
    stroke: $colorGrey;
    transition: 0.2s;
    margin-left: 2px;
  }

  &:hover {
    svg {
      stroke: $colorBlack;
    }
  }
}
