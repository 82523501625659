@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/common";

.physicalStatus {
  padding: 2px 8px;
  text-transform: uppercase;
  border-radius: 20px;
  line-height: 1;
  position: relative;
  font-size: $size12;
  display: inline-flex;
  align-items: center;

  &.stocked {
    border: 1px solid #22c55e;
    background: #dcfce7;
    color: #15803d;
  }

  &.inventory {
    border: 1px solid #fcceee;
    background: #fdf2fa;
    color: #c11574;
  }

  &.installed {
    border: 1px solid #14b8a6;
    background: #dffbf4;
    color: #115e59;
  }

  &.missing {
    border: 1px solid #f59e0b;
    background: #fef3c7;
    color: #b45309;
  }

  &.production {
    border: 1px solid #64748b;
    background: #f1f5f9;
    color: #1e293b;
  }

  &.warranty {
    border: 1px solid #2db2c8;
    background: #ebfcff;
    color: #165964;
  }

  &.damaged {
    border: 1px solid #ef4444;
    background: #fee2e2;
    color: #b91c1c;
  }

  &.packed {
    border: 1px solid #a855f7;
    background: #f3e8ff;
    color: #6b21a8;
  }

  &.preinstalled {
    border: 1px solid #14b8a6;
    background: #dffbf4;
    color: #115e59;
  }

  &.transferring {
    border: 1px solid #3b82f6;
    background: #dbeafe;
    color: #1e40af;
  }
}
