@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.toggleContainer {
  display: inline-flex;
  cursor: pointer;
}

.toggleSlider {
  position: relative;
  width: 30px;
  height: 18px;
  background-color: #cbd5e1;
  border-radius: 18px;
  transition: 0.2s;
}

.toggleHandle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 5px solid #fff;
  transition: 0.2s;
  box-shadow: -2px 2px 2px rgb(0 0 0 / 5%);
}

.checked.toggleSlider {
  background-color: #00bbdc;
}

.checked .toggleHandle {
  transform: translateX(12px);
}

input[type="checkbox"] {
  display: none;
}

.toggleWrapper {
  display: inline-flex;
  align-items: center;
  position: relative;

  &.reverse {
    flex-direction: row-reverse;
  }
}

.toggleText {
  font-size: $size14;
  padding: 0 8px;
}
