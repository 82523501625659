@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.modalNoteText {
  font-size: $size14;
  color: #165964;
  padding: 6px 10px;
  border-radius: 10px;
  background: #ebfcff;
}

.spinnerWrapper {
  position: absolute;
  z-index: 1;
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
}
