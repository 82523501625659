@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.monitoringMembership {
  @media only screen and (max-width: $tablet) {
    max-width: 100%;
    margin-bottom: 24px;
  }
}

.valueNote {
  font-size: 12px;
  font-weight: 400;
  color: #64748b;
}

.chartWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: relative;
}

.chart {
  position: absolute;
  bottom: -126px;
}
