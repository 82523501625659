@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.inputWrapper {
  display: flex;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.inputNumber {
  font-size: $size14;
  color: #64748b;
  min-width: 25px;
  text-align: center;
  height: 20px;
  border-right: 1px solid #e2e8f0;
  margin-right: 8px;
  margin-top: 10px;
  line-height: 1.4;
}

.inputSN {
  flex-shrink: 3;
  margin-bottom: 0;
}

.clearButton {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  margin-left: 8px;
}
