@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/common";
.alertStatus {
  text-transform: uppercase;
  font-size: $size12;
  font-weight: 500;
  display: inline-flex;
  align-items: center;

  &::before {
    display: inline-flex;
    margin-right: 6px;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    content: "";
  }

  &.active {
    &::before {
      background: #22c55e;
    }
  }

  &.frozen {
    &::before {
      background: #475569;
    }
  }

  &.acknowledged {
    &::before {
      background: #2db2c8;
    }
  }
}
