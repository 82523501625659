@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/common";

.orderStatus {
  padding: 2px 8px 2px 6px;
  text-transform: uppercase;
  border-radius: 20px;
  line-height: 1;
  position: relative;
  font-size: $size12;
  display: inline-flex;
  align-items: center;

  &::before {
    display: inline-flex;
    margin-right: 2px;
    position: relative;
    top: 1px;
  }

  &.new {
    background: #dcfce7;
    color: #15803d;

    &::before {
      content: url("../../../../assets/svg/status-new.svg");
    }
  }

  &.pending {
    background: #fef3c7;
    color: #b45309;

    &::before {
      content: url("../../../../assets/svg/status-pending.svg");
    }
  }

  &.confirmed {
    background: #cdfcff;
    color: #134f66;

    &::before {
      content: url("../../../../assets/svg/status-confirmed.svg");
    }
  }

  &.packed {
    background: #f3e8ff;
    color: #7e22ce;

    &::before {
      content: url("../../../../assets/svg/status-packed.svg");
    }
  }

  &.shipping {
    background: #dbeafe;
    color: #2563eb;

    &::before {
      content: url("../../../../assets/svg/status-shipped.svg");
    }
  }

  &.fulfilled {
    background: #e0e7ff;
    color: #4338ca;

    &::before {
      content: url("../../../../assets/svg/status-fulfilled.svg");
    }
  }

  &.denied {
    background: #fee2e2;
    color: #b91c1c;

    &::before {
      content: url("../../../../assets/svg/status-denied.svg");
    }
  }

  &.canceled {
    background: #fee2e2;
    color: #b91c1c;

    &::before {
      content: url("../../../../assets/svg/status-canceled.svg");
    }
  }
}
