@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.statusButtonWrapper {
  margin-right: 12px;
}

.statusButton {
  display: inline-flex;
  font-size: $size12;
  font-weight: 500;
  text-transform: uppercase;
  padding: 2px 8px;
  border-radius: 20px;
  margin-left: 4px;
  cursor: pointer;

  &.disabled {
    color: #cbd5e1;
    border: 1px solid #cbd5e1;
    background: #f1f5f9;
  }

  &.transfer {
    color: #164d63;
    border: 1px solid #06b3d4;
    background: #cff9fe;
  }

  &.missing {
    color: #b45309;
    border: 1px solid #f59e0b;
    background: #fef3c7;
  }
}
