@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.periodButtonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: -8px;
}

.separator {
  height: 1px;
  width: calc(100% + 40px);
  margin-top: 20px;
  margin-bottom: 12px;
  margin-right: 20px;
  position: relative;
  left: -20px;
  background: #e2e8f0;
}

.periodButton {
  display: inline-flex;
  height: 36px;
  border-radius: 10px;
  border: 1px solid#E2E8F0;
  background: #fff;
  width: calc(25% - 4px);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.15s;
  font-size: $size14;

  &.active,
  &:hover {
    border-color: #43e0f8;
    background: #ebfcff;
  }
}
