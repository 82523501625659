@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.widgetWrapper {
  width: 100%;
  max-width: calc(50% - 12px);

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin-bottom: 24px;
  }
}

.widgetContent {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.widgetBlock {
  flex: 1;
  border-radius: 10px;
  border: 1px;
  border-color: #e2e8f0;
  border-style: solid;
  text-align: center;
}

.widgetBlockHeader {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #e2e8f0;
  color: #0f172a;
  padding-top: 6px;
  padding-bottom: 6px;
}

.widgetBlockContent {
  padding: 16px;
}

.widgetBlockTotal p:first-child {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.widgetBlockTotal p:last-child {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #64748b;
}

.widgetBlockAvailable {
  padding-top: 22px;
  padding-bottom: 12px;
  border-bottom: 1px solid #f1f5f9;
  display: flex;
  justify-content: space-between;
}

.widgetBlockAvailable span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.widgetBlockAvailable span:first-child {
  background-color: #dcfce7;
  color: #15803d;
  padding: 2px 8px 2px 8px;
  border-radius: 10px;
}

.widgetBlockPacked {
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
}

.widgetBlockPacked span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.widgetBlockPacked span:first-child {
  background-color: #f3e8ff;
  color: #0f172a;
  padding: 2px 8px 2px 8px;
  border-radius: 10px;
}

.valueNote {
  font-size: 12px;
  font-weight: 400;
  color: #64748b;
}

.chartWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: relative;
}

.chart {
  position: absolute;
  bottom: -126px;
}

.text {
  font-size: $size12;
  color: #64748b;
  font-family: $secondaryFont;
}

.available,
.packed {
  display: inline-flex;
  align-items: center;

  &::before {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-flex;
    margin-right: 4px;
    content: "";
  }
}

.available {
  &:before {
    background-color: #fef3c7;
  }
}

.packed {
  &:before {
    background-color: #fcd34d;
  }
}
