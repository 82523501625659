@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.widgetWrapper {
  @extend .widgetWrapper;
  width: 100%;
  max-width: 100%;
  min-height: 193px;

  @media only screen and (max-width: 1200px) {
    margin-bottom: 24px;
  }

  @media only screen and (max-width: $tablet) {
    width: 100%;
  }
}

.alertWidgetSubtitle {
  font-size: $size12;
  margin: 16px 0 18px;
}

.indicatorsWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.indicatorWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.indicatorValue {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #0f172a;
}

.indicator {
  color: #475569;
  font-size: $size12;
}

.summaryIndicators {
  display: inline-flex;
  align-items: center;
  margin: 0 2px;
  color: #475569;
  font-size: $size12;
  margin-bottom: 28px;
  padding: 4px 10px 4px 5px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  margin-bottom: 0;

  i {
    display: inline-flex;
    padding: 0 5px;
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
    color: #0f172a;
  }
}
