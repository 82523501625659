@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.modalTabsWrapper {
  padding: 20px 20px 0;
}

.modalTabs {
  padding: 4px;
  background: #f1f5f9;
  border-radius: 40px;
  display: flex;
}

.modalTab {
  height: 32px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  font-size: $size14;
  font-weight: 500;
  color: #94a3b8;
  cursor: pointer;

  &.active {
    background: #fff;
    box-shadow:
      0px 1px 2px 0px rgba(51, 65, 85, 0.04),
      0px 1px 3px 0px rgba(51, 65, 85, 0.04);
    color: #334155;
  }
}
