@import "../../../assets/scss/variables";

.draggableItem {
  padding: 20px;
  display: flex;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;

  &::before {
    display: inline-flex;
    position: relative;
    top: 2px;
    margin-right: 8px;
    content: url("../../../assets/svg/icon-dragg.svg");
  }

  &:hover {
    background: #f8fafc;
  }
}

.dragging {
  background: #f8fafc;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
}

.toggleHide {
  display: inline-flex;
  margin-left: auto;
  opacity: 0.6;
  cursor: pointer;

  &.showIcon {
    content: url("../../../assets/svg/eyeClosed.svg");
  }

  &.hideIcon {
    content: url("../../../assets/svg/eyeOpened.svg");
  }
}

.hidden {
  color: #cbd5e1;
}
