.selectedDeviceSection {
  display: flex;
  flex-direction: column;
}

.selectedDevicesHeader {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  background-color: #f8fafc;
  position: relative;
  cursor: pointer;

  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #64748b;
    text-transform: uppercase;
  }

  svg {
    position: absolute;
    right: 16px;
    fill: #0f172a;
  }
}

.showSelectedDevices {
  svg {
    transform: rotate(90deg);
  }
}

.selectedDevicesBody {
  display: flex;
  flex-direction: column;
  padding: 20px 16px 0 16px;
  max-height: 140px;
  overflow: auto;
}

.selectedDeviceRow {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.selectedDeviceRowItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: #64748b;
    margin-bottom: 4px;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #0f172a;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 10px;
  }
}
