@import "../../../../assets/scss/variables";

.performanceTestStatus {
  padding: 4px 8px;
  border-radius: 6px;
  line-height: 16px;
  position: relative;
  font-size: $size12;
  display: inline-flex;
  align-items: center;
  border: 1px solid #e2e8f0;
  text-transform: capitalize;

  &::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
  }

  &.passed {
    color: #15803d;

    &::before {
      background: #22c55e;
    }
  }

  &.issue {
    color: #b91c1c;

    &::before {
      background: #ef4444;
    }
  }

  &.scheduled {
    color: #2db2c8;

    &::before {
      background: #43e0f8;
    }
  }

  &.rescheduled {
    color: #2563eb;

    &::before {
      background: #3b82f6;
    }
  }

  &.running {
    color: #f59e0b;

    &::before {
      background: #e3930a;
    }
  }

  &.clickable {
    cursor: pointer;
  }
}
