@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.detailsWrapper {
  display: flex;
  margin-top: 12px;
}

.detailsItem {
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  padding: 16px;
  margin-right: 12px;
  min-width: 115px;
  max-width: 400px;
}

.detailsTitle {
  font-weight: normal;
  color: #64748b;
  font-size: $size14;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  word-break: break-word;
  word-wrap: break-word;
}

.detailsIcon {
  margin-right: 4px;
}

.detailsText {
  font-size: $size14;
  font-weight: 500;
  word-break: break-word;
  word-wrap: break-word;

  ul {
    li {
      ul,
      ol {
        padding-left: 20px;
      }
    }
  }
}
