@import "../../../assets/scss/common";
@import "../../../assets/scss/variables";

div[class*="react-dropdown-select-dropdown"] {
  z-index: 9999 !important;
}

.error {
  border: 1px solid $colorRed !important;
}

.disabled {
  opacity: 1 !important;
  border: none !important;
  box-shadow: none;
  background: #f1f5f9;

  input::placeholder {
    color: #cbd5e1 !important;
  }
}
