@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.orderDetails {
  display: flex;
  justify-content: space-between;
  font-family: $secondaryFont;
}

.orderDetailsItem {
  width: 33%;
}

.orderDetailsLabel {
  display: block;
  font-size: $size14;
  margin-bottom: 4px;
  color: #64748b;
}

.orderDetailsValue {
  font-weight: 500;
  display: block;
}

.separator {
  height: 1px;
  width: 100%;
  margin: 20px 0;
  background: #e2e8f0;
}

.deviceWrapper {
  display: flex;
  align-items: center;
  font-family: $secondaryFont;

  &::before {
    display: block;
    content: url("../../assets/svg/device.svg");
  }
}

.deviceQtyWrapper {
  padding-left: 16px;
  margin-right: 20px;
}

.deviceName {
  display: block;
  font-weight: 500;
  margin-bottom: 4px;
}

.deviceQty {
  display: block;
  font-size: $size14;
  color: #64748b;
}

.orderNote {
  font-size: $size12;
  color: #64748b;
  margin-top: 30px;

  &::before {
    content: "*";
    color: $colorBlue;
  }
}
