@import "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Inter:wght@400;500;700;900&display=swap";
@import "./reset";
@import "./variables";
@import "./common";

html,
body {
  color: $colorBlack;
  font-family: $mainFont, sans-serif;
  font-weight: 400;
}

body {
  background: linear-gradient(114deg, #4ae9ff 3.88%, #627afa 94.88%);
  overflow-x: hidden;
}
