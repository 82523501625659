@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.modalTitle {
  margin: 0;
}

.modalSubtitle {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #64748b;
}

.statsScrollableArea {
  position: relative;
  height: calc(100vh - 93px);
  overflow: scroll;
  padding-bottom: 30px;
}

.modalSubtitleWrapper {
  background: #f8fafc;
  padding: 8px 20px;
  display: flex;
  align-items: center;
}

.modalSubtitleIcon {
  margin-right: 8px;
}

.devicesDataContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 0 20px 20px;
}

.devicesDataItem {
  max-width: 160px;

  &.column3 {
    max-width: none;
    width: calc(33.33% - 20px);
  }
}

.devicesDataText {
  font-size: 0.75rem;
  font-weight: 500;
  margin-top: 4px;
  word-break: break-all;

  &.cooling {
    color: #3b92f6;
  }

  &.heating {
    color: #ef4444;
  }

  &.good {
    color: #22c55e;
  }

  &.fair {
    color: #f59e0b;
  }

  &.bad {
    color: #ef4444;
  }
}

.chartsWrapper {
  padding: 0 20px;
  min-height: 480px;
}

.drawerTabsWrapper {
  padding: 0 20px;
  margin-bottom: 24px;
}

.drawerTabs {
  display: flex;
  gap: 5px;
  padding: 4px;
  border-radius: 32px;
  background-color: #f1f5f9;
}

.drawerTab {
  flex: 1;
  color: #94a3b8;
  border-radius: 32px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  padding: 8px 0;
  transition: all 0.3s;

  &.active {
    background-color: #fff;
    color: #334155;
    cursor: initial;
  }

  &:hover {
    color: #334155;
    background-color: #fff;
  }
}

.chartOptionsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 15px;
}

.tabButtonsWrapper {
  flex: 1;
  display: flex;
  align-items: center;
}

.tempToggleWrapper {
  position: absolute;
  display: flex;
  flex: 1;
  gap: 6px;
  top: 17px;
  right: 35px;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;

  span {
    font-size: 14px;
    font-weight: 500;
    color: #64748b;
  }
}

.tabButton {
  color: #64748b;
  margin-right: 24px;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 14px;
  margin-bottom: -1px;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  &:hover {
    color: #334155;
  }

  &.active {
    color: #334155;
    border-color: #43e0f8;
  }
}

.chartContainer {
  margin-top: 10px;
  position: relative;
  height: 200px;
}

.chartContainerWithLabel {
  height: 240px;
}

.emptyReportDataWrapper {
  margin-top: 52px;
}

.airQualityChart {
  margin-top: 52px;
}

.barChartContainer {
  position: relative;
  margin-top: 30px;
  height: 280px;
}
