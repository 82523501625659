* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote,
q {
  quotes: none;

  &::before,
  &::after {
    content: "";
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a,
button {
  outline: none;
}

strong {
  font-weight: 700;
}

input,
textarea {
  outline: none;
}

address {
  font-style: normal;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

a {
  text-decoration: none;
}

hr {
  display: block;
  height: 1px;
  width: 90%;
  background: #ccc;
  margin: 10px 0;
}
