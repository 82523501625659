@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.closeIconClassName {
  z-index: 2;
  top: 18px;
}

.hiddenModal {
  display: none;
}

.changeSubscriptionSubModal {
  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 20px;

    p {
      font-size: 14px;
      color: #475569;
      margin-top: 4px;
      text-align: center;
    }

    span {
      font-weight: 700;
    }
  }

  .subscriptionSubModalFooter {
    flex-direction: column;
    gap: 12px;
  }

  .iconWrapper {
    display: flex;
    width: 44px;
    height: 44px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    background-color: #ebfcff;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
