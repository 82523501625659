.mdxeditor-toolbar {
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  margin-bottom: 10px;
}

.mdxeditor-root-contenteditable ul,
.mdxeditor-root-contenteditable ol {
  margin-left: 20px;
}

.mdxeditor-root-contenteditable h1 {
  font-size: 2.25rem;
  margin-bottom: 10px;
}

.mdxeditor-root-contenteditable h2 {
  font-size: 1.75rem;
  margin-bottom: 10px;
}

.mdxeditor-root-contenteditable h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.mdxeditor-root-contenteditable h4 {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.mdxeditor-root-contenteditable h5 {
  font-size: 1rem;
  margin-bottom: 10px;
}

.mdxeditor-root-contenteditable h6 {
  font-size: 0.75rem;
  margin-bottom: 10px;
}
