@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.leftNavWrapper {
  display: flex;
  align-items: center;
}

.selectWrapper {
  width: 330px;
  margin: 0 12px;
}
