@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.widgetsWrapper {
  flex-wrap: wrap;
}

.widgetsRowWrapper {
  width: 100%;
  margin-bottom: 24px;
  padding: 24px 8px;
  background: #f8fafc;
  border-radius: 40px;

  &.half {
    width: calc(50% - 12px);
    margin-bottom: 0;

    @media only screen and (max-width: 1200px) {
      width: 100%;
      margin-bottom: 24px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.widgetsHalfRowWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .widgetsRow {
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 1200px) {
    br {
      display: none;
    }
  }
}

.widgetsRow {
  display: flex;
  width: 100%;

  @media only screen and (max-width: 1200px) {
    flex-wrap: wrap;

    .widgetWrapper {
      width: 100%;
      max-width: 100%;
    }
  }
}

.widgetsRowTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 16px;
  padding-left: 16px;
}
