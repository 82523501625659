@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.tagButton {
  font-size: $size12;
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  display: inline-flex;
  height: 20px;
  padding: 0 8px;
  border-radius: 20px;
  border: 1px solid #cbd5e1;
  background: #f1f5f9;
  color: #cbd5e1;
  margin-left: 4px;

  cursor: pointer;

  &.yellow {
    border-color: #f59e0b;
    color: #b45309;
    background: #fef3c7;
  }

  &.red {
    border-color: #ef4444;
    color: #b91c1c;
    background: #fee2e2;
  }
}
