@import "../../../../assets/scss/variables";

.optInOutStatus {
  font-size: 14px;
  font-weight: 400;
}

.Opted-in {
  color: #22c55e;
}

.Opted-out {
  color: #64748b;
}

.tooltip {
  max-width: 220px;
  padding: 4px 10px !important;
  border-radius: 6px !important;
  border: 1px solid #e2e8f0 !important;
  z-index: 1500;
  font-size: $size12;
}

.tooltipArrow {
  display: none;
}
