@import "../../../assets/scss/variables";

.button {
  height: 54px;
  border-radius: 16px;
  font-weight: 500;
  font-size: 1rem;
  font-family: $secondaryFont;
  transition: all 0.2s ease-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media only screen and (max-width: $tablet) {
    height: 40px;
    font-size: $size14;
    border-radius: 10px;
  }

  &.colored {
    color: $colorWhite;
    box-shadow: 3px 3px 0 0 $colorBlack;
    background: linear-gradient(0deg, rgb(237 237 237 / 20%) 0%, rgb(237 237 237 / 20%) 100%),
      linear-gradient(114deg, #4ae9ff 3.88%, #627afa 94.88%);

    &:hover {
      box-shadow: 1px 1px 0 0 $colorBlack;
    }
  }

  &.white {
    border: 1px solid $colorPurple;
    background: $colorWhite;
    color: $colorPurple;

    &:hover {
      border-color: #6148ff;
      color: #6148ff;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.halfWidth {
    width: calc(50% - 8px);
  }

  &.normal {
    width: auto;
    padding: 0 24px;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    opacity: 0.8;

    &.colored {
      &:hover {
        box-shadow: 3px 3px 0 0 $colorBlack;
      }
    }
  }
}
