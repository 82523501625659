.modalHeader {
  display: flex;
  align-items: center;
  padding: 10px 36px 10px 16px;
  border-bottom: 1px solid #e2e8f0;
}

.performanceIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 44px;
  width: 44px;
  height: 44px;
  background-color: #f5f5f5;
  border-radius: 50%;
  margin-right: 16px;
  border: 1px solid #e2e8f0;

  svg {
    fill: none;

    path {
      stroke: #59a6fd;
    }
  }
}

.performanceTestInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: #0f172a;
    margin-bottom: 4px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #64748b;
  }
}

.modalBody {
  padding: 16px;
}

.performanceTestActionsWrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.performanceTestAction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  padding: 16px 30px;
  row-gap: 8px;
  cursor: pointer;

  &:hover {
    border: 1px solid #43e0f8;
    background-color: #ebfcff;

    svg path {
      fill: #165964;
    }
  }

  svg path {
    fill: #0f172a;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    color: #0f172a;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    color: #475569;
  }
}

.activeAction {
  border: 1px solid #43e0f8;
  background-color: #ebfcff;

  svg path {
    fill: #165964;
  }
}

.scheduleSection,
.labelSection {
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 4px;
  }
}

.scheduleSection {
  position: relative;
  margin-top: 12px;
}

.selectItemWrapper {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  margin: 4px;

  &:hover {
    background-color: #f8fafc;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #0f172a;
  }
}

.addBtnWrapper {
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 16px;

  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #2db2c8;
  }
}

.plusIcon {
  font-size: 16px;
  line-height: 14px;
  margin-right: 4px;
}
