@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.input {
  height: 48px;
  padding: 0 16px;
  width: 100%;
  font-size: $size14;
  font-family: $mainFont;
  padding-right: 50px;
  border-radius: 16px;
  border: 1px solid #dde4ee;
  transition: 0.2s;

  &::placeholder {
    font-size: $size14;
    font-family: $mainFont;
    color: #66768e;
  }

  &:focus,
  &:active {
    border: 1px solid #a7b8d0;
  }

  &.error {
    border-color: $colorRed;
  }

  @media only screen and (max-width: $tablet) {
    height: 40px;
    padding: 0 12px;
    border-radius: 10px;
  }
}

.iconButton {
  position: absolute;
  right: 16px;
  top: 14px;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    transition: 0.15s;
    opacity: 0.6;
  }

  @media only screen and (max-width: $tablet) {
    top: 10px;
    right: 12px;
  }
}

.showIcon {
  display: inline-flex;
  content: url("../../../assets/svg/eyeClosed.svg");
}

.hideIcon {
  display: inline-flex;
  content: url("../../../assets/svg/eyeOpened.svg");
}
