@import "../../../assets/scss/variables";

.modalHeader {
  position: relative;
  z-index: 1;
  padding: 16px 60px 16px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e2e8f0;

  &.borderless {
    border-bottom: none;
  }

  &.modalHeaderShadow {
    box-shadow:
      0 1px 2px 0 rgb(226 232 240 / 24%),
      0 2px 2px 0 rgb(148 163 184 / 12%);
  }
}

.modalHeaderTitle {
  font-size: $size18;
  font-weight: 500;
}

.modalHeaderSubtitle {
  color: #475569;
  font-size: $size14;
}

.modalHeaderIconWrapper {
  margin-right: 16px;
  display: flex;
}
