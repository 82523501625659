@import "../../assets/scss/variables";
@import "../../assets/scss/common";

.spinnerWrapper {
  position: absolute;
  z-index: 1;
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}

.subscriptionPlanContainer {
  font-size: 14px;
  margin-bottom: 10px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    background-color: #f8fafc;
    border-radius: 12px;
    padding: 15px 20px 15px 10px;

    > div {
      cursor: pointer;
      color: #2db2c8;
      font-size: 14px;
    }
  }
}
