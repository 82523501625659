@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/common";

.deviceStatus {
  &::before {
    content: "";
    display: inline-flex;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #fff;
    margin-right: 8px;
    position: relative;
    top: -1px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12));
  }

  &.online {
    &:before {
      background: #22c55e;
    }
  }

  &.offline {
    &:before {
      background: #94a3b8;
    }
  }
}
